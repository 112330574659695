html,
body,
#root,
.App {
  height: 100%
}

.underline {
  text-decoration: underline;
}

.headline{
  margin-bottom: 50px;
  max-width: 900px;
  text-align: center;
  font-weight: 300;
/* set at the top of the section */
  /* margin-top: 0; */
}

.section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

#section1 {
  background-color: #eef2f7;
}

#section2 {
  height: 1500px;
  background: linear-gradient(224deg, #ce71e0 0%, #489aff 100%);
  position: relative;
}

.howsItWork:hover .down-arrow {
  transform: scale(85%);
}

.howsItWork {
  margin: 20px 0;
  font-size: 35px;
  cursor: pointer;
  bottom: 10px;
  display: flex;
  align-items: center;
  font-weight: bolder;
  position: absolute;
  padding-top: 25px;
}

.down-arrow {
  max-width: 40px;
  margin-right: 10px;
  transition-duration: 200ms;
}

.answer {
  height: 0;
  overflow: hidden;
  transition-duration: 1000ms;
}

.answer.open {
  height: 100%;
}

.faq-row-wrapper {
  border-radius: 13px;
  width: 90%;
  position: absolute;
  top: 30px;
  /* padding: 16px; */
}

.faq-row-wrapper .faq-row:last-child {
  border: none;
  border-bottom: none;
}

.faq-row-wrapper section.faq-title {
  padding-left: 10px;
}

.row-title {
  margin-left: 10px;
  font-weight: bold;
}

.row-title-text {
  text-shadow: 0 0 4px black;
}

.faq-row {
  padding: 10px !important;
}

.faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

/* .faq-row-wrapper .faq-body .faq-row:not(:last-child) .row-content .row-content-text{
  margin-left: 20px;
  margin-right: 20px;
} */

.step-top-gradient {
  background-size: 400% 400%;
  width: 500px;
  height: 500px;
  /* background: linear-gradient(270deg, #e66465, #9198e5); */
  background: linear-gradient(80deg, #00dbff, #ff78e2);
  background-size: 200% 200%;
  mask-image: url(./images/stepone.png);
  mask-mode: alpha;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  animation: colorspin 5s ease infinite;
  max-height: 300px;
}

.step-bottom {
  max-width: 500px;
}

.step-image img {
  max-width: 300px;
  height: 300px;
}

.step-content-lower {
  max-width: 300px;
}

@keyframes colorspin {
  0% {
    background-position: 0% 59%
  }

  50% {
    background-position: 100% 42%
  }

  100% {
    background-position: 0% 59%
  }
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative!important;
}

.step-content {
  font-size: 26px;
  font-weight: bold;
}

.examples {
  display: flex;
  height: 300px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  /* overflow: scroll; */
}

.example-image {
  scroll-snap-align: left;
  margin-right: 10px;
  width: 300px;
  height: 300px;
  border-radius: 7px;
  background-color: #eef2f7;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.4);
}

a {
  color: #b8b8f3;
}

.faq {
  position: absolute;
  top: 100px;
}

.faq-row-wrapper * {
  color: white !important;
}

.step-image {
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#step-one .step-image>* {
  height: 150px;
  max-width: 300px;
}

.step-text{
  display: flex;
  flex-direction: column;
}

.step-number {
  font-size: 33px;
  margin-right: 10px;
  /* text-align: center; */
}

.step-text-parent{
  display: flex;
  flex-direction: row;
  margin-bottom: 30px!important;
  height: 100px!important;
  margin-top: 20px!important;
  align-items: center!important;
}

@media only screen and (max-width: 600px) {
  .headline {
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 15px;
    max-width: 90%;
  }

  .examples {
    justify-content: flex-start;
    overflow-x: scroll;
  }

  .section {
    height: fit-content;
  }

  .howsItWork {
    position: relative;
    bottom: 0;
  }

  .step-list {
    flex-direction: column;
  }

  .step-image img {
    max-width: 150px;
    height: 150px;
  }

  #step-one .step-image>* {
    max-width: 200px;
    height: 100px;
  }

  #step-one .step-image .step-bottom {
    max-width: 200px;
    height: 75px;
  }

  #step-three .step-image>* {
    max-width: 100px;
    height: 100px;
  }
}

form {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 20px 2px rgb(0 0 0 / 20%);
  padding: 20px;
  align-items: left;
  justify-content: center;
  margin: 20px 0;
}

.faq-row-wrapper form label {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-weight: 600;
  color: black !important;
}

form input[type="email"] {
  margin: 15px 0;
  text-indent: 10px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-size: 14px;
  outline: none;
  font-family: Helvetica;
}

form textarea {
  margin: 15px 0;
  height: 150px;
  resize: none;
  padding: 10px;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 14px;
  font-family: Helvetica;
}


form button {
  width: 150px;
  height: 35px;
  background: #333;
  color: #fff;
  font-weight: bold;
  border: 1px solid #333;
  margin-top: 30px;
  border-radius: 2px;
  cursor: pointer;
}

.faq-row-wrapper form textarea, .faq-row-wrapper form input{
  color: #000!important;
}

#step-number-one{
  font-size: 42px;
  margin-right: 12px;
}
#step-number-two{
  font-size: 40px;
  margin-right: 18px;
}
#step-number-three{
  font-size: 54px;
  margin-right: 10px;
}

.main-headline {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}
